export default [
  {
    path: 'upsell',
    name: 'website.upsell',
    component: () => import('@/views/website/upsell'),
  },
  {
    path: 'upsell/products-from-the-same-collections/:widgetType',
    name: 'website.upsell.products_from_the_same_collections',
    component: () => import('@/views/website/upsell/products-from-the-same-collections'),
  },
  {
    path: 'upsell/post-purchase',
    name: 'website.upsell.post_purchase',
    component: () => import('@/views/website/upsell/post-purchase'),
  },
  {
    path: 'upsell/post-purchase/create',
    name: 'website.upsell.post_purchase.create',
    component: () => import('@/views/website/upsell/post-purchase/detail'),
  },
  {
    path: 'upsell/post-purchase/update/:id',
    name: 'website.upsell.post_purchase.update',
    component: () => import('@/views/website/upsell/post-purchase/detail'),
  },
  {
    path: 'upsell/quantity-offer',
    name: 'website.upsell.quantity.list',
    component: () => import('@/views/website/upsell/quantily'),
  },
  {
    path: 'upsell/quantity-offer/create',
    name: 'website.upsell.quantity.create',
    component: () => import('@/views/website/upsell/quantily/detail'),
  },
  {
    path: 'upsell/quantity-offer/update/:id',
    name: 'website.upsell.quantity.update',
    component: () => import('@/views/website/upsell/quantily/detail'),
  },
];
