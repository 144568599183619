import Request from '../index';
import STORAGE_NAME from '@/const/storage';

const storeRequest = new Request();
let token = localStorage.getItem(STORAGE_NAME.ACCESS_TOKEN);
let storeDomain = localStorage.getItem(STORAGE_NAME.STORE_DOMAIN);
if (storeDomain) {
  storeRequest.setBaseURL('https://' + storeDomain + '/api/');
}
if (token) {
  storeRequest.setToken(token);
}
export default storeRequest;
