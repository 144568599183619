export default [
  // {
  //   path: 'dropship/catalog',
  //   name: 'website.catalog',
  //   component: () => import('@/views/website/dropship/catalog'),
  // },
  // {
  //   path: 'dropship/catalog/:id',
  //   name: 'website.catalog.detail',
  //   component: () => import('@/views/website/dropship/catalog/Detail.vue'),
  // },
  // {
  //   path: 'dropship/import-list',
  //   name: 'website.import-list',
  //   component: () => import('@/views/website/dropship/import-list'),
  // },
  {
    path: 'catalog',
    name: 'website.catalog',
    component: () => import('@/views/website/catalog'),
  },
  {
    path: 'catalog/:id',
    name: 'website.catalog.detail',
    component: () => import('@/views/website/catalog/detail'),
  },
];
