import { importCsvReview, importProductReview, getReviews, deleteReviews, updateReview } from '@/apis/review';

const initialState = {
  reviews: [],
  currentReview: {},
  options: {
    page: 1,
    itemsPerPage: 50,
    totalPages: 1,
  },
  headers: [
    // {
    //   text: 'Customer Email',
    //   align: 'start',
    //   sortable: false,
    //   value: 'customerEmail',
    // },
    { text: 'Customer Name', value: 'customerName' },
    { text: 'Review', value: 'review' },
    // { text: 'Product Type', value: 'productType' },
    { text: 'Rate', value: 'rating' },
    // { text: 'Status', value: 'isPublished' },
    { text: 'Actions', value: 'actions', sortable: false },
  ],
  productReviews: [],
  totalProductReviews: 0,
  rating: 0,
};

export const state = { ...initialState };

export const actions = {
  async importCsvReview(context, params) {
    const { data } = await importCsvReview(params);
    context.dispatch('fetchReviews', params);
    return data;
  },
  async importProductReview(context, dataReview) {
    await importProductReview(dataReview.file, dataReview.productId);
    context.dispatch('fetchReviews', {
      type: 'store',
    });
  },
  async fetchReviews(context, params) {
    const { data } = await getReviews(params);
    context.commit('setReviews', data);
    context.commit('setReviewOptions', params);
    return data;
  },
  async deleteReviews(context, params) {
    const { data } = await deleteReviews(params);
    context.dispatch('fetchReviews', {
      type: 'store',
    });
    return data;
  },
  async updateReview(context, review) {
    const { data } = await updateReview(review);
    return data;
  },

  async getProductReviews({ commit }, params) {
    const { data } = await getReviews(params);
    commit('setProductReviews', data);
    return data;
  },
  async deleteProductReviews(context, params) {
    const { data } = await deleteReviews(params);
    context.dispatch('fetchReviews', {
      product_id: params.productId,
    });
    return data;
  },
};

/* eslint no-param-reassign: ['error', { 'props': false }] */
export const mutations = {
  setReviews(state, data) {
    state.reviews = data.reviews;
    state.options.totalPages = Math.ceil(data.total / state.options.itemsPerPage);
    // if (state.options.totalPages < state.options.page) {
    //   state.options.page = state.options.totalPages;
    // }
  },
  setCurrentReview(state, data) {
    state.currentReview = data;
  },
  setReviewOptions(state, opts) {
    state.options.page = parseInt(opts?.page) || 1;
  },
  setProductReviews(state, data) {
    state.productReviews = data.reviews;
    state.totalProductReviews = data.total;
    state.rating = data.rating;
  },
};

const getters = {
  getReviews(state) {
    return state.reviews;
  },
  getReviewHeaders() {
    return state.headers;
  },
  getReviewOptions() {
    return state.options;
  },
  getCurrentReview() {
    return state.currentReview;
  },
  productReviews() {
    return state.productReviews;
  },
  totalProductReviews() {
    return state.totalProductReviews;
  },
  averageProductReviewRate() {
    return state.rating;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
