<template>
  <div class="images-uploader">
    <div class="images-preview">
      <v-row no-gutters v-if="images.length > 0">
        <template v-if="multiple">
          <v-col
            class="px-3 py-3"
            v-for="(img, key) in images"
            :key="key"
            :lg="columns.lg"
            :md="columns.md"
            :sm="columns.sm"
            :xs="columns.xs"
          >
            <v-hover>
              <template class="px-3" v-slot:default="{ hover }">
                <v-card class="mx-auto" relative :max-width="maxWidth">
                  <!-- Image -->
                  <v-img contain :src="image.url(img.src)" :lazy-src="image.url(img.src)" max-height="150">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <!-- Loading -->
                  <v-row v-if="loading" class="loading-img fill-height ma-0 absolute" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey"></v-progress-circular>
                  </v-row>
                  <!-- overlay -->
                  <v-fade-transition>
                    <v-overlay class="text-center btn-group-hover-image" v-if="hover" absolute color="#036358">
                      <v-btn min-width="auto" width="auto" @click="changeImage(key)">Change</v-btn>
                      <v-btn
                        class="ml-1"
                        min-width="auto"
                        width="auto"
                        @click="
                          showPreview = true;
                          preview.src = img.src;
                        "
                      >
                        <v-icon small>fas fa-eye</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="showAlt"
                        class="ml-1"
                        min-width="auto"
                        width="auto"
                        @click="
                          showEditAlt = true;
                          preview.src = img.src;
                        "
                      >
                        ALT
                      </v-btn>
                      <v-btn class="ml-1" min-width="auto" width="auto" @click="deleteFile(0)">
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
              </template>
            </v-hover>
          </v-col>
          <!-- imput file -->
          <v-col class="px-3 py-3" :lg="columns.lg" :md="columns.md" :sm="columns.sm" :xs="columns.xs">
            <div class="file-drop-area">
              <div class="text-center">
                <div class="fake-btn"><v-icon large>mdi-publish</v-icon></div>
                <div class="file-msg">or drag and drop files here</div>
              </div>
              <v-file-input
                ref="file"
                accept="image/*"
                class="file-input"
                @change="chooseFile"
                :multiple="multiple"
                v-model="files"
              ></v-file-input>
            </div>
          </v-col>
        </template>
        <template v-else>
          <v-hover v-if="images && images.length > 0">
            <template class="px-3" v-slot:default="{ hover }">
              <v-card :min-width="minWidth" :min-height="minHeight" class="mx-auto" relative :max-width="maxWidth">
                <!-- Image -->
                <v-img
                  contain
                  :src="image.url(images[0].src)"
                  :lazy-src="image.url(images[0].src)"
                  max-with="100%"
                  max-height="250"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <!-- loading -->
                <v-row v-if="loading" class="loading-img fill-height ma-0 absolute" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey"></v-progress-circular>
                </v-row>
                <!-- overlay -->
                <v-fade-transition>
                  <v-overlay v-if="hover" absolute color="#036358">
                    <v-btn min-width="auto" width="auto" @click="changeImage">Change</v-btn>
                    <v-btn
                      class="ml-1"
                      min-width="auto"
                      width="auto"
                      @click="
                        showPreview = true;
                        preview.src = images[0].src;
                      "
                    >
                      <v-icon x-small>fas fa-eye</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="showAlt"
                      class="ml-1"
                      min-width="auto"
                      width="auto"
                      small
                      @click="
                        showEditAlt = true;
                        preview.src = images[0].src;
                      "
                    >
                      ALT
                    </v-btn>
                    <v-btn v-if="!hiddenDelete" class="ml-1" min-width="auto" width="auto" @click="deleteFile(0)">
                      <v-icon x-small>mdi-delete</v-icon>
                    </v-btn>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </template>
      </v-row>
    </div>
    <!-- input file -->
    <div class="file-drop-area" v-show="!images || images.length == 0">
      <div class="text-center">
        <div class="fake-btn"><v-icon large>mdi-publish</v-icon></div>
        <div class="file-msg">or drag and drop files here</div>
      </div>
      <v-file-input
        accept="image/*"
        class="file-input"
        @change="chooseFile"
        :multiple="multiple"
        v-model="files"
        ref="file"
      ></v-file-input>
    </div>

    <!-- Dialog show preview -->
    <v-dialog v-model="showPreview" width="500">
      <v-card>
        <v-card-title class="headline lighten-2"> Preview Image </v-card-title>
        <v-card-text>
          <v-img max-height="600" contain :src="image.url(preview.src)"></v-img>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showPreview = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog edit alt -->
    <v-dialog v-model="showEditAlt" width="500">
      <v-card>
        <v-card-title class="headline lighten-2"> Edit image alt text </v-card-title>

        <v-row no-gutters class="px-3 py-3">
          <v-col cols="12" sm="12" md="6">
            <v-img max-height="600" contain :src="image.url(preview.src)"></v-img>
          </v-col>
          <v-col class="px-3 py-3" cols="12" sm="12" md="6">
            <v-text-field v-model="preview.alt"></v-text-field>
            <span>
              Write a brief description of this image to improve search engine optimization (SEO) and accessibility for
              visually impaired customers.
            </span>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showEditAlt = false"> Cancel </v-btn>
          <v-btn color="primary" text @click="showEditAlt = false"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { uploadService } from '@/apis/upload.s';
import axios from 'axios';
import image from '@/helpers/image';

export default {
  props: {
    hiddenDelete: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: function() {
        return [
          {
            src:
              'https://st3.depositphotos.com/13159112/17145/v/600/depositphotos_171453724-stock-illustration-default-avatar-profile-icon-grey.jpg',
          },
        ]; //Object in images array must be { src: 'link to image', alt: '', fullPath: '' }
      },
    },
    minWidth: {
      type: String,
      default: '0',
    },
    minHeight: {
      type: String,
      default: '0',
    },
    maxWidth: {
      type: String,
      default: '250',
    },
    columns: {
      type: Object,
      default: function() {
        return { lg: 12, md: 12, sm: 12, xs: 12 };
      },
    },
    showAlt: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      files: [],
      preview: {
        alt: '',
        src: '',
      },
      index: 0,
      change: false,
      errors: [],
      overlay: false,
      absolute: true,
      dialog: false,
      showPreview: false,
      showEditAlt: false,
      loading: false,
      image,
    };
  },
  methods: {
    objectId() {
      return this.hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => this.hex(Math.random() * 16));
    },

    hex(value) {
      return Math.floor(value).toString(16);
    },
    async chooseFile() {
      let vm = this;
      vm.loading = true;
      this.files.forEach(async file => {
        try {
          let type = file.name.split('.')[file.name.split('.').length - 1];
          let id = this.objectId();
          let urlData = await uploadService.get({ filename: `image-${id}.${type}` });
          if (urlData) {
            axios
              .put(urlData.data.presignedUrl, file, {
                headers: {
                  'Content-Type': `${file.type}`,
                },
              })
              .then(function() {
                let obj = {
                  alt: file.name,
                  alt_text: file.name,
                  src: urlData.data.imagePath,
                  fullPath: vm.image.url(urlData.data.imagePath),
                };
                vm.$store.commit('setMessages', {
                  messages: 'Upload image successfully!',
                  type: 'success',
                });
                if (vm.multiple) {
                  if (vm.change) {
                    vm.$set(vm.images, vm.index, obj);
                    vm.$forceUpdate();
                  } else {
                    vm.images.push(obj);
                  }
                } else {
                  if (vm.change) {
                    vm.$set(vm.images, 0, obj);
                    vm.$forceUpdate();
                  } else {
                    vm.images.push(obj);
                  }
                }
                vm.$emit('callback', vm.images);
                vm.loading = false;
                vm.change = false;
              })
              .catch(function(error) {
                vm.loading = false;
                vm.change = false;
                vm.$store.commit('setMessages', {
                  messages: 'Upload Image Fail',
                  type: 'error',
                });
                console.log(error);
              });
          }
        } catch (error) {
          vm.loading = false;
          vm.$store.commit('setMessages', {
            messages: 'Upload Image Fail',
            type: 'error',
          });
          console.log(error, 'asdas');
        }
      });
      vm.loading = false;
      this.$forceUpdate();
    },
    changeImage(key) {
      this.change = true;
      this.index = key;
      this.$refs.file.$refs.input.click();
    },
    /*
    func delete file resources
    */
    deleteFile(key) {
      this.files.splice(key, 1);
      this.images.splice(key, 1);
      this.$emit('callback', this.images);
      console.log(this.images);
    },
  },
};
</script>

<style lang="scss">
.images-uploader {
  .images-preview {
    .image-container {
      border: 1px solid #ccc;
      margin: 5px;
      border-radius: 10px;
      cursor: pointer;
    }
    .v-btn__content {
      font-size: 9px !important;
    }
    .v-btn {
      padding: 0px 10px;
    }
  }
  .btn-group-hover-image {
    .v-btn {
      padding: 5px;
    }
  }
  .loading-img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0.46;
    background-color: rgb(3, 99, 88);
    border-color: rgb(3, 99, 88);
  }
  form {
    width: 500px;
    height: 200px;
    border: 3px dashed #ccc;
  }
  form p {
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 170px;
    color: #ccc;
    font-family: Arial;
  }
  form input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
  }
  form button {
    margin: 0;
    color: #fff;
    background: #16a085;
    border: none;
    width: 508px;
    height: 35px;
    margin-top: -20px;
    margin-left: -4px;
    border-radius: 4px;
    border-bottom: 4px solid #117a60;
    transition: all 0.2s ease;
    outline: none;
  }
  form button:hover {
    background: #149174;
    color: #0c5645;
  }
  form button:active {
    border: 0;
  }
  .file-drop-area {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    min-height: 150px;
    padding: 25px;
    border: 3px dashed #ccc;
    border-radius: 3px;
    transition: 0.2s;
    &.is-active {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }

  .fake-btn {
    flex-shrink: 0;
    background-color: rgba(255, 255, 255, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    padding: 8px 15px;
    text-transform: uppercase;
  }

  .file-msg {
    font-size: small;
    font-weight: 300;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .file-input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    input {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
    }
    .v-input__control {
      height: 100%;
    }
    .v-input__slot {
      height: 100% !important;
    }
    .v-text-field__slot {
      height: 100%;
    }
    .v-file-input__text {
      cursor: pointer;
    }
    &:focus {
      outline: none;
    }
  }
}
</style>
