export default [
  {
    path: 'settings',
    name: 'website.settings',
    component: () => import('@/views/website/settings'),
  },
  {
    path: 'settings/payment-provider',
    name: 'website.settings.payments',
    component: () => import('@/views/website/settings/components/Payments'),
  },
  {
    path: 'settings/payout',
    name: 'website.settings.payout',
    component: () => import('@/views/website/settings/components/Payout'),
  },
  {
    path: 'settings/proxy',
    name: 'website.settings.proxy',
    component: () => import('@/views/website/settings/components/Proxy'),
  },
  // {
  //   path: 'menus/update/:id',
  //   name: 'website.menus.update',
  //   props: true,
  //   component: () => import('@/views/website/menus/Update'),
  // },
  {
    path: 'settings/account/add-staff',
    name: 'website.settings.account.add',
    component: () => import('@/views/website/settings/components/account/AddAccount'),
  },
  {
    path: 'settings/account/:id',
    name: 'website.settings.account.update',
    component: () => import('@/views/website/settings/components/account/EditAccount'),
  },
  {
    path: 'settings/account',
    name: 'website.settings.account',
    component: () => import('@/views/website/settings/components/account'),
  },
  {
    path: 'settings/shipping',
    name: 'website.settings.shipping',
    component: () => import('@/views/website/settings/components/shipping'),
  },
  {
    path: 'settings/shipping/create',
    name: 'website.settings.shipping.create',
    component: () => import('@/views/website/settings/components/shipping/Create'),
  },
  {
    path: 'settings/shipping/update/:id',
    props: true,
    name: 'website.settings.shipping.update',
    component: () => import('@/views/website/settings/components/shipping/Update'),
  },
  {
    path: 'settings/notifications',
    name: 'website.settings.notifications',
    component: () => import('@/views/website/settings/components/notifications'),
  },
  {
    path: 'settings/notifications/customize',
    name: 'website.settings.notifications.customize',
    component: () => import('@/views/website/settings/components/notifications/Customize'),
  },
  {
    path: 'settings/notifications/add-recipient',
    name: 'website.settings.notifications.recipient',
    component: () => import('@/views/website/settings/components/notifications/AddRecipient'),
  },
  {
    path: 'settings/notifications/orders/order_confirmation',
    name: 'website.settings.notifications.order.confirmation',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/orders/order_canceled',
    name: 'website.settings.notifications.order.canceled',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/orders/order_refund',
    name: 'website.settings.notifications.order.refund',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/orders/abandoned_checkout_email_1',
    name: 'website.settings.notifications.order.abandoned.checkout.email.1',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/orders/abandoned_checkout_email_2',
    name: 'website.settings.notifications.order.abandoned.checkout.email.2',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/orders/abandoned_checkout_email_3',
    name: 'website.settings.notifications.order.abandoned.checkout.email.3',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/shipping/fulfillment_request',
    name: 'website.settings.notifications.shipping.fulfillment.request',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/shipping/shipping_confirmation',
    name: 'website.settings.notifications.shipping.shipping.confirmation',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/shipping/shipping_update',
    name: 'website.settings.notifications.shipping.shipping.update',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/shipping/shipping_out_for_delivery',
    name: 'website.settings.notifications.shipping.shipping.delivery',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/shipping/shipping_delivered',
    name: 'website.settings.notifications.shipping.shipping.delivered',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/customer/customer_account_activation',
    name: 'website.settings.notifications.customer.customer.account.activation',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/customer/customer_account_welcome',
    name: 'website.settings.notifications.customer.customer.account.welcome',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/customer/customer_account_password_reset',
    name: 'website.settings.notifications.customer.customer.account.password.reset',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/review/review_request',
    name: 'website.settings.notifications.review.review.request',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/review/review_remind',
    name: 'website.settings.notifications.review.review.remind',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/staff/new_order',
    name: 'website.settings.notifications.staff.new.order',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/aftersale/email1',
    name: 'website.settings.notifications.aftersale.email1',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/aftersale/email2',
    name: 'website.settings.notifications.aftersale.email2',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/general',
    name: 'website.settings.general',
    component: () => import('@/views/website/settings/components/general/index'),
  },
  {
    path: 'settings/checkout',
    name: 'website.settings.checkout',
    component: () => import('@/views/website/settings/components/checkout'),
  },
  {
    path: 'settings/legal',
    name: 'website.settings.legal',
    component: () => import('@/views/website/settings/components/legal'),
  },
  {
    path: 'settings/taxes',
    name: 'website.settings.taxes',
    component: () => import('@/views/website/settings/components/taxes'),
  },
  {
    path: 'settings/taxes/:id',
    name: 'website.settings.taxes.update',
    component: () => import('@/views/website/settings/components/taxes/Update'),
  },
  {
    path: 'settings/sale-channels',
    name: 'website.settings.sale-channels',
    component: () => import('@/views/website/sale-channels/index'),
  },
  {
    path: 'settings/sale-channels/google',
    name: 'website.settings.sale-channels.google',
    component: () => import('@/views/website/sale-channels/Form'),
  },
  {
    path: 'settings/sale-channels/ggads',
    name: 'website.settings.sale-channels.ggads',
    component: () => import('@/views/website/sale-channels/Form'),
  },
  {
    path: 'settings/sale-channels/bingads',
    name: 'website.settings.sale-channels.bingads',
    component: () => import('@/views/website/sale-channels/Form'),
  },
  {
    path: 'settings/sale-channels/tiktok',
    name: 'website.settings.sale-channels.tiktok',
    component: () => import('@/views/website/sale-channels/Form'),
  },
  {
    path: 'settings/sale-channels/criteo',
    name: 'website.settings.sale-channels.criteo',
    component: () => import('@/views/website/sale-channels/Form'),
  },
  {
    path: 'settings/sale-channels/pinterest',
    name: 'website.settings.sale-channels.pinterest',
    component: () => import('@/views/website/sale-channels/Form'),
  },
  {
    path: 'settings/sale-channels/facebook',
    name: 'website.settings.sale-channels.facebook',
    component: () => import('@/views/website/sale-channels/Form'),
  },
  {
    path: 'settings/sale-channels/klaviyo',
    name: 'website.settings.sale-channels.klaviyo',
    component: () => import('@/views/website/sale-channels/Form'),
  },
  {
    path: 'settings/sale-channels/trustPilot',
    name: 'website.settings.sale-channels.trustPilot',
    component: () => import('@/views/website/sale-channels/Form'),
  },
  {
    path: 'settings/billing',
    name: 'website.settings.billing',
    component: () => import('@/views/website/settings/components/billing'),
  },
  {
    path: 'settings/notifications/orders/abandoned_checkout_sms_1',
    name: 'website.settings.notifications.order.abandoned.checkout.sms.1',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/orders/abandoned_checkout_sms_2',
    name: 'website.settings.notifications.order.abandoned.checkout.sms.2',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
  {
    path: 'settings/notifications/orders/abandoned_checkout_sms_3',
    name: 'website.settings.notifications.order.abandoned.checkout.sms.3',
    component: () => import('@/views/website/settings/components/notifications/Model'),
  },
];
