import { productApi } from '../../apis/product';
import { AnalyticService } from '../../apis/analytics';

import {
  FETCH_ALL_PRODUCTS_DONE,
  FETCH_PRODUCT_SALES_REPORT_DONE,
  FETCH_PRODUCT_OPTION_TEMPLATES_DONE,
} from '../mutations.type';
import { FETCH_ALL_PRODUCTS, FETCH_PRODUCT_SALES_REPORT, FETCH_PRODUCT_OPTION_TEMPLATES } from '../actions.type';

const initialState = {
  products: [],
  headers: [
    {
      text: 'Product Title',
      align: 'start',
      sortable: false,
      value: 'title',
    } /**
    "net_qty": 0,
        "total_sale": 0,
        "orders": 0,
        "aoi": 0,
        "aov": 0,
        "view_content": 0,
        "add_to_cart": 0,
        "reached_checkout": 0,
        "session_converted": 0,
        "conversion_rate": "0.00" */,
    { text: 'Page Views', value: 'view_content' },
    { text: 'Add to cart', value: 'add_to_cart' },
    { text: 'Reached checkout', value: 'reached_checkout' },
    { text: 'Session Converted', value: 'session_converted' },
    { text: 'Order', value: 'orders' },
    // { text: 'Conversion rate', value: 'conversion_rate' },
    { text: 'Net quantity', value: 'net_qty' },
    { text: 'Average order value', value: 'aov' },
    { text: 'Average order items', value: 'aoi' },
    { text: 'Total sales', value: 'total_sale' },
  ],
  options: {
    page: 1,
    itemsPerPage: 20,
    totalPage: 1,
  },
  optionTemplates: [],
};

export const state = { ...initialState };

export const actions = {
  async [FETCH_PRODUCT_SALES_REPORT](context, params) {
    const { data } = await AnalyticService.fetchProductSalesReport(params);
    context.commit(FETCH_PRODUCT_SALES_REPORT_DONE, data);
    return data;
  },
  async [FETCH_PRODUCT_OPTION_TEMPLATES](context) {
    const { data } = await productApi.getOptionTemplates();
    context.commit(FETCH_PRODUCT_OPTION_TEMPLATES_DONE, data);
    return data;
  },
};

/* eslint no-param-reassign: ['error', { 'props': false }] */
export const mutations = {
  [FETCH_PRODUCT_SALES_REPORT_DONE](state, data) {
    state.products = data.products;
    state.options.totalPage = Math.ceil(data.total / state.options.itemsPerPage);
  },
  [FETCH_PRODUCT_OPTION_TEMPLATES_DONE](state, data) {
    state.optionTemplates = data;
  },
};

const getters = {
  getProducts(state) {
    return state.products;
  },
  getHeaders() {
    return state.headers;
  },
  getOptions() {
    return state.options;
  },
  getOptionTemplates() {
    return state.optionTemplates;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
