import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';
function get(file) {
  let token = localStorage.getItem(STORAGE_NAME.ACCESS_TOKEN);
  if (token) storeRequest.setToken(token);
  const path = '/media/presigned-url-upload';
  return storeRequest.get(path + '?filename=' + file.filename);
}

function upload(url, file) {
  return storeRequest.put(url, file);
}

export const uploadService = {
  get,
  upload,
};
