import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';
// import router from '@/router';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);

function create(data) {
  return storeRequest.post('/products', {
    product: data,
  });
}
function update(id, data) {
  return storeRequest.put('/products/' + id, {
    product: data,
  });
}
function updatePositionInCollection(id, data) {
  return storeRequest.put(`/products/${id}/collection/update-position`, data);
}
function getById(id) {
  return storeRequest.get('/products/' + id);
}
function getByIdSmart(id) {
  return storeRequest.get('/collections/smart/products/' + id);
}
function getByHandle(handle) {
  return storeRequest.get('/products/handle/' + handle);
}
// /api/collections/smart/products/:_product_id
function get(params) {
  return storeRequest.get('/products', {
    params: { ...params },
  });
}
function count(params) {
  return storeRequest.get('/products/count', {
    params: { ...params },
  });
}
function taskDownloaded(id) {
  return storeRequest.put('/products/export/csv/tasks/' + id, {
    task: { status: 3 },
  });
}
function updateTask(data, searchString, typeAll) {
  let param;
  let obj = {};
  if (data.ids) {
    obj.ids = data.ids;
  }
  if (data.lazy === true) {
    obj.lazy = true;
  }
  if (typeAll) {
    obj.type = 'all';
  }
  if (searchString) {
    obj.search = searchString;
  }
  if (data.query) {
    let dataQuery = data.query;
    if (dataQuery.tab === 'published') {
      obj.published = 'true';
    } else if (dataQuery.tab === 'unpublished') {
      obj.published = 'false';
    }
    if (dataQuery._query) {
      let _query = JSON.parse(dataQuery._query);
      _query.forEach(q => {
        if (q.compare) {
          obj[q.field] = q.value;
          obj[q.fieldCompare] = q.compare;
        } else {
          obj[q.field] = Array.isArray(q.value) ? q.value.join(',') : q.value;
        }
      });
    }
    let queries = { ...obj, ...dataQuery };
    param = queries;
    console.log('queries', queries);
    delete queries._query;
    delete queries.query;
    delete queries.tab;
  }
  if (!data.query) {
    param = { ...obj };
  }
  return storeRequest.get('/products/export/csv/tasks/', { params: param });
}
function getByCollectionId(id, params) {
  return storeRequest.get('/products/collections/' + id, {
    params: { ...params },
  });
}
function remove(id) {
  return storeRequest.delete('/products/' + id);
}
function removeAll(id, params) {
  return storeRequest.delete('/products/' + id, params);
}

function checkRegistered(ids) {
  return storeRequest.get('/products/checkRegistered?ids=' + ids);
}

function updateBulk(data, searchString, tab, typeAll) {
  console.log(data.query);
  // if else để khi select all thì mới cần query
  let obj = {};
  let arr = [];
  let dataQuery = data.query;
  console.log(dataQuery);
  // để định dạng query có key: value giống yêu cầu từ api
  if (dataQuery.tab === 'published') {
    obj.published = 'true';
  } else if (dataQuery.tab === 'unpublished') {
    obj.published = 'false';
  }
  if (typeAll) {
    obj.type = 'all';
  }
  if (searchString) {
    obj.search = searchString;
  }
  if (dataQuery._query) {
    let _query = JSON.parse(dataQuery._query);
    _query.forEach(q => {
      if (q.compare) {
        obj[q.field] = q.value;
        obj[q.fieldCompare] = q.compare;
      } else {
        obj[q.field] = Array.isArray(q.value) ? q.value.join(',') : q.value;
      }
    });
  }
  let queries = { ...obj, ...dataQuery };
  console.log('queries', queries);
  delete queries._query;
  delete queries.tab;
  let queryString = ``;
  for (const key in queries) {
    if (Object.hasOwnProperty.call(queries, key)) {
      const value = queries[key];
      if (value) {
        queryString += key + '=' + encodeURIComponent(value) + '&';
      }
    }
  }
  console.log(queryString);
  return storeRequest.patch('/products/?' + queryString, data);
}
function importCSVProduct(data) {
  return storeRequest.post('/products/import/csv', data);
}
function importProcess(data) {
  // tiến trình import list product
  return storeRequest.get('/products/import/csv', data);
}
function directExport(data) {
  return storeRequest.get('/products/export/csv', data);
}
function exportCSVProduct(data, searchString, typeAll) {
  let param;
  let obj = {};
  if (data.lazy === true) {
    obj.lazy = true;
  }
  if (data.ids) {
    obj.ids = data.ids;
  }
  if (data.email) {
    obj.email = data.email;
  }
  if (typeAll) {
    obj.type = 'all';
  }
  if (searchString) {
    obj.search = searchString;
  }
  if (data.query) {
    let dataQuery = data.query;
    if (dataQuery.tab === 'published') {
      obj.published = 'true';
    } else if (dataQuery.tab === 'unpublished') {
      obj.published = 'false';
    }
    if (dataQuery._query) {
      let _query = JSON.parse(dataQuery._query);
      _query.forEach(q => {
        if (q.compare) {
          obj[q.field] = q.value;
          obj[q.fieldCompare] = q.compare;
        } else {
          obj[q.field] = Array.isArray(q.value) ? q.value.join(',') : q.value;
        }
      });
    }
    let queries = { ...obj, ...dataQuery };
    param = queries;
    console.log('queries', queries);
    delete queries._query;
    delete queries.query;
    delete queries.tab;
  }
  if (!data.query) {
    param = { ...obj };
  }
  return storeRequest.get('/products/export/csv/', { params: param });
}
function getSVProduct(data) {
  return storeRequest.get('/products/export/csv');
}

function getProductFeed() {
  return storeRequest.get('/feed');
}

function getCountFeed() {
  return storeRequest.get('/feed/count');
}

function getProductFeedById(id) {
  return storeRequest.get('/feed/' + id);
}

function updateProductFeed(params) {
  return storeRequest.put('/feed/' + params.productFeed._id, params);
}

function createProductFeed(params) {
  return storeRequest.post('/feed', params);
}

function getGoogleProductCategory() {
  return storeRequest.get('/feed/google/categories');
}

function deleteProductFeed(params) {
  return storeRequest.delete('/feed/' + params.id);
}

function getProductTags(params) {
  return storeRequest.get('/products/tags/list', {
    params: { ...params },
  });
}
// lấy tag của nhiều sp
function getTags(params) {
  return storeRequest.get('/products/tags/list' + params);
}

function getProductTypes(params) {
  return storeRequest.get('/products/types/list', {
    params: { ...params },
  });
}

function getProductVendors(params) {
  return storeRequest.get('/products/vendors/list', {
    params: { ...params },
  });
}

function getOptionTemplates(params) {
  return storeRequest.get('/products/options/template');
}

// Pixel
function updatePixels(id, data) {
  return storeRequest.post(`/products/${id}/add-pixels`, data);
}

export const productApi = {
  create,
  get,
  getById,
  update,
  remove,
  removeAll,
  getByCollectionId,
  count,
  updateTask,
  updateBulk,
  importCSVProduct,
  importProcess,
  directExport,
  exportCSVProduct,
  taskDownloaded,
  getSVProduct,
  getProductFeed,
  getCountFeed,
  getProductFeedById,
  updateProductFeed,
  getGoogleProductCategory,
  createProductFeed,
  deleteProductFeed,
  getProductTags,
  getTags,
  getProductTypes,
  getProductVendors,
  getByIdSmart,
  getByHandle,
  getOptionTemplates,
  updatePositionInCollection,
  checkRegistered,
  updatePixels,
};
