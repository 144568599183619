import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const options = {
  theme: {
    light: true,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#1e5577',
        secondary: '#60a5fa',
        info: '#4c75cf',
        success: '#22c55e',
        warning: '#eab308',
        error: '#f43f5e',
        border: '#f3f3f3',
        foreground: '#475569',
        background: '#f2f6f9',
        navigation: '#1e293b',
      },
    },
  },
};

export default new Vuetify(options);
