<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { userService } from '@/apis/user.s';
import { mapMutations } from 'vuex';
const DefaultLayout = () => import('./layouts/Default');
const AuthLayout = () => import('./layouts/Auth');

import { FETCH_PAGE_PREFERRENCE } from '@/store/actions.type';
import STORAGE_NAME from '@/const/storage';
import verifyMixin from '@/mixins/verify';

export default {
  props: ['authPage'],
  mixins: [verifyMixin],
  components: {
    DefaultLayout,
    AuthLayout,
  },
  data() {
    return {
      layout: 'default-layout',
    };
  },
  name: 'App',
  watch: {
    // eslint-disable-next-line prettier/prettier
    $route: function() {
      if (this.$route.query?.code) {
        this.$store.commit('setCodeRefCreateStore', this.$route.query.code);
      }
    },
  },
  methods: {
    async getLayout() {},
  },
  async created() {
    if (this.$route.query?.code) {
      this.$store.commit('setCodeRefCreateStore', this.$route.query.code);
    }
    let TOKEN = localStorage.getItem(STORAGE_NAME.ACCESS_TOKEN);
    if (TOKEN) {
      await this.$store.dispatch(FETCH_PAGE_PREFERRENCE);
    }
  },
};
</script>
