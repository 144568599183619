<template>
  <div class="text-center modal">
    <v-dialog v-model="$parent[model]" :transition="transition" :width="width" :max-width="maxWidth">
      <v-card class="pa-4" outlined>
        <v-btn class="close-icon" icon @click="$parent[model] = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h2 class="primary--text" v-once>{{ title }}</h2>
        <!-- Content -->
        <slot name="content"></slot>
        <v-divider></v-divider>
        <!-- Footer -->
        <div v-if="showFooter" class="d-flex justify-end mt-4" style="gap: 12px">
          <v-btn text outlined @click="$parent[model] = false">
            Cancel
          </v-btn>
          <v-btn :disabled="isLoading" :loading="isLoading" color="primary" depressed @click="submit">
            {{ titleButton }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    /*
     String props model
    */
    model: {
      type: String,
      default: '',
    },
    /*
     Width dialog
    */
    width: {
      type: String,
      default: '500',
    },
    /*
     Max width dialog
    */
    maxWidth: {
      type: String,
      default: '',
    },
    /*
     transition:
      fab-transition,
      fade-transition,
      expand-transition,
      scale-transition,
      scroll-x-transition,
      scroll-x-reverse-transition,
      scroll-y-transition,
      scroll-y-reverse-transition,
      slide-x-transition,
      slide-x-reverse-transition,
      slide-y-transition,
      slide-y-reverse-transition,
    */
    transition: {
      type: String,
      default: 'scale-transition',
    },
    /*
     Show Footer
    */
    showFooter: {
      type: Boolean,
      default: true,
    },
    /*
     Title dialog
    */
    title: {
      type: String,
      default: '',
    },
    /*
     Title submit button
    */
    titleButton: {
      type: String,
      default: 'OK',
    },
    /**
     * Callback
     */
    callback: {
      type: Function,
      default(element) {},
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    /*
     fnc submit and close
    */
    clearDisable() {
      this.$parent.turnOffBtn = false;
    },
    submit() {
      this.callback(this.$el);
      // console.log(this.$el);
      this.$parent.turnOffBtn = true;
      setTimeout(this.clearDisable, 1000);
    },
  },
};
</script>

<style lang="scss">
.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.content-modal {
  max-height: 700px;
  overflow-y: auto;
}
</style>
