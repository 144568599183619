import subscriptionService from '@/apis/subscription';
export default {
  state() {
    return {
      trialInfo: {
        status: false,
        dayLeft: null,
        diff: null,
      },
      currentSubscription: null,
      subscriptionList: [],
      subscriptionSelected: null,
      nextSubscription: null,
      store60daysRevenue: null,
    };
  },
  mutations: {
    setTrialInfo(state, data) {
      state.trialInfo = data;
    },
    setCurrentSubscription(state, data) {
      state.currentSubscription = data;
    },
    setNextSubscription(state, data) {
      state.nextSubscription = data;
    },
    setSubscriptionList(state, data) {
      state.subscriptionList = data;
    },
    setSubscriptionSelected(state, data) {
      state.subscriptionSelected = data;
    },
    setStore60DaysRevenue(state, data) {
      state.store60daysRevenue = data;
    },
  },
  actions: {
    async getCurrentSubscription({ commit }) {
      try {
        const { data } = await subscriptionService.currentSubscription();
        if (data.data) {
          commit('setCurrentSubscription', data?.data?.origin);
          commit('setNextSubscription', data?.data?.nextRank);
          commit('setStore60DaysRevenue', data?.data?.store60daysRevenue);
        } else {
          const { data: time } = await subscriptionService.getTrialTime();
          const dayLeft =
            time.diff < 0
              ? `Your free trial expired ${time.duration}`
              : `You have ${time.duration.replace(/[^0-9]/g, '')} days left in your trial`;
          commit('setTrialInfo', {
            status: true,
            dayLeft,
            diff: time.diff,
          });
        }
      } catch (error) {
        console.log('🚀 ~ file: index.vue:192 ~ getCurrrentSubscription ~ error:', error);
      }
    },
    async getListSubscription({ state, commit }) {
      try {
        const { data } = await subscriptionService.list();
        if (data.length) {
          const list = data.filter(item => {
            if (state.currentSubscription) {
              return item.status === 'ACTIVE' && item.code != state.currentSubscription.code;
            } else return item.status === 'ACTIVE';
          });
          commit('setSubscriptionList', list);
        }
      } catch (error) {
        console.log('🚀 ~ file: subscription.js:35 ~ getListSubscription ~ error:', error);
      }
    },
    async getSubscriptionByID({ commit }, id) {
      try {
        const { data } = await subscriptionService.findById(id);
        commit('setSubscriptionSelected', data);
      } catch (error) {
        console.log('🚀 ~ file: subscription.js:35 ~ getListSubscription ~ error:', error);
      }
    },
    async confirmPlan({ state }) {
      try {
        const { data } = await subscriptionService.startPlanSubscription({
          subscriptionId: state.subscriptionSelected._id,
        });
        return data.success;
      } catch (error) {
        console.log('🚀 ~ file: subscription.js:73 ~ confirmPlan ~ error:', error);
        return false;
      }
    },
    async getPaymentInfo() {
      try {
        const { data } = await subscriptionService.getPaymentInfo();
        data.cardHolder.cardNumber = data.cardHolder.cardNumber.replace(/(\d{4})/g, '$1 ');
        return data;
      } catch (error) {
        console.log('🚀 ~ file: subscription.js:83 ~ getPaymentInfo ~ error:', error);
        return null;
      }
    },
    async updatePaymentInfo(_, paymentInfo) {
      try {
        paymentInfo.cardHolder.cardNumber = paymentInfo.cardHolder.cardNumber.replace(/\s/g, '');
        const { data } = await subscriptionService.updatePaymentInfo(paymentInfo);
        return data?.success;
      } catch (error) {
        console.log('🚀 ~ file: subscription.js:94 ~ updatePaymentInfo ~ error:', error);
        return false;
      }
    },
  },
};
