import { AnalyticService } from '../../apis/analytics';
import { FETCH_TOTAL_SALES_END } from '../mutations.type';
import * as moment from 'moment';

import { FETCH_TOTAL_SALES } from '../actions.type';
const initialState = {
  chartData: {
    datasets: [
      {
        data: [],
      },
    ],
  },
  options: {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 50,
            callback: function(value, index, values) {
              const currencySign = localStorage.getItem('currencySign') || '$';
              return currencySign + value;
            },
          },
          gridLines: {
            display: true,
          },
        },
      ],
      xAxes: [
        {
          type: 'time',
          time: {
            displayFormats: {
              hour: 'hA',
            },
          },
          ticks: {
            callback: function(label, index, labels) {
              if (/\s/.test(label)) {
                return label.split(' ');
              } else {
                return label;
              }
            },
            maxTicksLimit: 4,
            maxRotation: 0,
            padding: 10,
            fontSize: 10,
          },
          gridLines: { display: false },
        },
      ],
    },
    legend: {
      display: false,
      labels: {
        display: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      fontSize: 12,
    },
    animation: {
      duration: 1,
      onComplete: function() {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;

        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';

        this.data.datasets.forEach(function(dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function(bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
      },
    },
  },
  totalSales: 0,
  uselTotalSales: 0,
  onlineStoreSales: 0,
};

export const state = { ...initialState };

export const actions = {
  async [FETCH_TOTAL_SALES](context, args) {
    let strStores = Array.isArray(args.stores) ? args?.stores.join(',') : args.stores;
    const { data } = await AnalyticService.fetchTotalSales(args.fromTime, args.toTime, strStores, args?.timezone);
    let maxValue = Math.max(...data.details.dataset);
    let stepSize = maxValue === 0 ? 20 : maxValue > 20 ? Math.round(maxValue / 4) : maxValue;
    initialState.options.scales.yAxes[0].ticks.stepSize = stepSize;
    let dataset = {
      type: 'bar',
      data: data.details.dataset,
      borderColor: '#1e5577',
      backgroundColor: '#1e5577',
      fill: false,
      pointRadius: 1,
      pointBackgroundColor: '#1e5577',
      pointHitRadius: 5,
    };
    let rs = {
      chartData: { datasets: [dataset], labels: data.details.labels },
      options: { ...initialState.options },
      totalSales: Math.round(data?.total_sales * 100) / 100 || 0,
      uselTotalSales: Math.round(data?.upsell_sales * 100) / 100 || 0,
      onlineStoreSales: Math.round(data?.online_store_sales * 100) / 100 || 0,
    };
    context.commit(FETCH_TOTAL_SALES_END, rs);
    return rs;
  },
};

/* eslint no-param-reassign: ['error', { 'props': false }] */
export const mutations = {
  [FETCH_TOTAL_SALES_END](state, data) {
    // console.log(data);
    state.chartData = data.chartData;
    state.options = data.options;
    state.totalSales = data.totalSales;
    state.uselTotalSales = data.uselTotalSales;
    state.onlineStoreSales = data.onlineStoreSales;
  },
};

const getters = {
  totalSalesReport(state) {
    return state.chartData;
  },
  chartSalesOpts(state) {
    return state.options;
  },
  totalSales(state) {
    return state.totalSales;
  },
  uselTotalSales(state) {
    return state.uselTotalSales;
  },
  onlineStoreSales(state) {
    return state.onlineStoreSales;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
