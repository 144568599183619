import Vue from 'vue';
import VueRouter from 'vue-router';
import menuFilterRoutes from '@/router/website/menufilter';
import productRoutes from '@/router/website/products';
import bookRoutes from '@/router/website/books';
import abandonedRoutes from '@/router/website/abandoned';
import collectionRoutes from '@/router/website/collections';
import ordersRoutes from '@/router/website/orders';
import menuRoutes from '@/router/website/menus';
import pageRoutes from '@/router/website/pages';
import themesRoutes from '@/router/website/themes';
import settingRoutes from '@/router/website/settings';
import redirectsRoutes from '@/router/website/redirects';
import domainRoutes from '@/router/website/domain';
import discountRoutes from '@/router/website/discount';
import dnsRoutes from '@/router/website/dns';
import profileRoutes from '@/router/website/profile';
import customerRoutes from '@/router/website/customer';
import preferencesRoutes from '@/router/website/preferences';
import watermarkRoutes from '@/router/website/watermark';
import inventoryRoutes from '@/router/website/inventory';
import productFeedsRoutes from '@/router/website/product-feeds';
import bulkUpdatesRoutes from '@/router/website/bulkupdates';
import sizechartRoutes from '@/router/website/sizechart';
import appRoutes from '@/router/website/app';
import analyticRoutes from '@/router/website/analytics';
import saleReportRoutes from '@/router/website/sales-reports';
import estimatedProfitRoutes from '@/router/website/estimated-profit';
import personalizedRoutes from '@/router/website/personalized';
import catalogRoutes from '@/router/website/catalog';
import memberRoutes from '@/router/website/member';
import pricingRoutes from '@/router/website/pricing';
import topUpRoutes from '@/router/website/topUp';
import utmAnalystics from '@/router/website/utm-analytics';
import upsellRoutes from './website/upsell';
import pixelRoutes from './website/pixel';
import notification from './website/notification';

const HomeWesite = () => import(/* webpackChunkName: "" */ '@/views//website/home');
const Home = () => import(/* webpackChunkName: "" */ '@/views/home');
const Website = () => import(/* webpackChunkName: "" */ '@/views/website');
const Page404 = () => import(/* webpackChunkName: "" */ '@/views/404');
const PageAuth = () => import(/* webpackChunkName: "" */ '@/views/auth');
const BookEditor = () => import('@/views/book-editor');
const BookEditor2 = () => import('@/views/book2');
const PrintHub = () => import('@/views/website/print-hub/editor');

const Survey = () => import(/* webpackChunkName: "" */ '@/views/auth/components/Survey');
const Logout = () => import(/* webpackChunkName: "" */ '@/views/auth/components/Logout');
const NoAccess = () => import(/* webpackChunkName: "" */ '@/views/website/access');

VueRouter.prototype.open = function(routeObject) {
  const { href } = this.resolve(routeObject);
  window.open(href, '_blank');
};
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
Vue.use(VueRouter);

let routers = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      props: true,
      component: PageAuth,
    },
    {
      path: '/admin',
      name: 'website',
      component: Website,
      children: [
        ...catalogRoutes,
        ...bulkUpdatesRoutes,
        ...abandonedRoutes,
        ...productRoutes,
        ...bookRoutes,
        ...menuFilterRoutes,
        ...menuRoutes,
        ...redirectsRoutes,
        ...pageRoutes,
        ...settingRoutes,
        ...collectionRoutes,
        ...ordersRoutes,
        ...themesRoutes,
        ...domainRoutes,
        ...discountRoutes,
        ...profileRoutes,
        ...customerRoutes,
        ...preferencesRoutes,
        ...watermarkRoutes,
        ...inventoryRoutes,
        ...productFeedsRoutes,
        ...sizechartRoutes,
        ...appRoutes,
        ...analyticRoutes,
        ...saleReportRoutes,
        ...estimatedProfitRoutes,
        ...utmAnalystics,
        ...personalizedRoutes,
        ...dnsRoutes,
        ...memberRoutes,
        ...pricingRoutes,
        ...topUpRoutes,
        ...upsellRoutes,
        ...pixelRoutes,
        ...notification,
        {
          path: 'no-access',
          name: 'no-access',
          props: true,
          component: NoAccess,
        },
        {
          path: 'book-editor/:bookId/:layoutId',
          name: 'book-editor',
          props: true,
          component: BookEditor,
        },
        {
          path: 'book2-editor/:bookId/:layoutId',
          name: 'book2-editor',
          props: true,
          component: BookEditor2,
        },
        {
          path: 'app/catalog-product/editor/:mockupId',
          name: 'print-hub-editor',
          props: true,
          component: PrintHub,
        },
        ...utmAnalystics,
      ],
    },

    {
      path: '/auth/:authPage?',
      name: 'auth',
      props: true,
      component: PageAuth,
    },
    {
      path: 'auth/accept',
      props: true,
      name: 'auth.accept',
      component: () => import('@/views/auth/components/email-account/CreateStaff'),
    },
    {
      path: 'auth/verify',
      props: true,
      name: 'auth.verify',
      component: () => import('@/views/auth/components/email-account/VerifyEmail'),
    },

    {
      path: '/shop/add/survey',
      name: 'auth/survey',
      props: true,
      component: Survey,
    },

    {
      path: '/logout',
      name: 'logout',
      props: true,
      component: Logout,
    },

    { path: '/test', component: () => import('@/views/website/test/DAT.vue') },
    { path: '/*', name: 'page.404', component: Page404 },
  ],
});

let blackRouters = [
  // 'website.themes',
  'website.settings.proxy',
  // 'website.settings.checkout',
  'website.settings.legal',
  // 'website.settings.payments',
  'website.settings.notifications',
  'website.settings.legal',
  'website.settings.taxes',
  'website.settings.billing',
  'website.settings.notifications',
  'website.apps',
  'website.member',
  'website.sizechart',
  'website.apps-migrate',
  'website.customer',
  'website.inventory',
  'website.product-personalized',
  'website.bulkupdates',
  'website.menufilter',
  'website.abandoned',
];
let whiteEmailList = ['support@dcomcy.com', 'admin@dcomcy.com', 'nguyenduy@dcomcy.com', 'account@ak11.work'];
routers.beforeEach((to, from, next) => {
  if (localStorage.getItem('dcomcy-user')) {
    let u = JSON.parse(localStorage.getItem('dcomcy-user'));
    let isBlackRouter = blackRouters.includes(to.name);
    if (true === isBlackRouter) {
      if (u?.email?.indexOf('@lattehub.com') > -1 || whiteEmailList.indexOf(u?.email) > -1) {
        return next();
      }
      return next({ name: 'page.404' });
    }
    return next();
  }
  return next();
});

export default routers;
